<template>
  <button type="button" class="flex items-center gap-x-8 rounded-lg px-12 py-8 text-12 font-semibold uppercase leading-12 hover:bg-gray-100" aria-label="Kurv" @click="openCart">
    <LazySharedIcon
      name="cart"
      class="h-24 w-24"
    />
    <span class="text-left lg:min-w-[48px]">
      <span class="hidden lg:inline-block">Kurv</span>
      <ClientOnly>
        {{ cart?.itemsCount > 0 ? ` (${cart.itemsCount})` : '' }}
      </ClientOnly>
    </span>
  </button>
  <LazySlideOver
    v-model="open"
    :title="title"
  >
    <div class="mt-10 flex grow flex-col">
      <template v-if="cart?.itemsCount > 0">
        <ul class="flex grow flex-col gap-y-15 pb-80">
          <li v-for="cartItem in cart.items" :key="cartItem.item.id" class="flex justify-between rounded-lg border p-8">
            <div class="flex gap-x-10">
              <div class="relative aspect-square w-[115px] overflow-hidden rounded-md bg-gray-50">
                <img v-if="cartItem.item.product?.media" :src="`${cartItem.item.product?.media?.imageUrl}?width=115&height=115&format=webp`" :srcset="`${cartItem.item.product?.media?.imageUrl}?width=115&height=115&format=webp 115w, ${cartItem.item.product?.media?.imageUrl}?width=230&height=230&format=webp 230w`" sizes="115px" :alt="`{{ cartItem.item.productName }} - {{ cartItem.item.variantName }}`">
                <div class="absolute inset-0 z-10 flex items-center justify-center bg-black/30">
                  <img :src="cartItem.item.product.brand?.logo?.imageUrl" width="75" height="15" class="h-[20px] w-auto max-w-[75px] invert" :alt="cartItem.item.product.brand?.title || 'Ukendt brand'">
                </div>
              </div>
              <div class="flex flex-col">
                <header>
                  <strong class="font-semibold">{{ cartItem.item.variantName }}</strong>
                </header>
                <div class="grow text-14">
                  <p v-if="cartItem.item.validity.label">
                    Betalingsfrekvens: {{ cartItem.item.validity.label }}
                  </p>
                  <p v-show="false">
                    Første levering: 🤷🏻‍♂️
                  </p>
                </div>
                <footer class="flex flex-col">
                  <strong class="font-semibold">{{ formatPrice(cartItem.item.placedPrice) }} kr.</strong>
                </footer>
              </div>
            </div>
            <button type="button" class="self-start hover:text-gray-400" @click="removeItem(cartItem.id)">
              <LazySharedIcon
                class="!size-24"
                name="delete"
              />
            </button>
          </li>

        </ul>
        <div class="w-full max-w-400 rounded-b-lg border-t bg-white pt-10 sm:pt-15">
          <Button
            class="w-full justify-between"
            to="/checkout"
            @click="open = false"
          >
            <span class="text-14 font-semibold">Videre til kassen</span>
            <strong class="font-semibold">{{ formatPrice(cart.pricing.total) }} kr.</strong>
          </Button>
        </div>
      </template>
      <p v-else class="pt-25 text-center">
        Din kurv er tom.
      </p>
    </div>
  </LazySlideOver>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { toast } from 'vue-sonner';
import { type ContentCart } from '@aller/nuxt-allerservice-sdk/transformers';

// State of cart slide-over.
const open = ref<boolean>(false);

// Dynamic cart title with items counter.
const title = computed((): string => `Kurv ${cart?.value?.itemsCount > 0 ? `(${cart?.value?.itemsCount || 0})` : ''}`);

// Get current cart from state.
const cart = useState<ContentCart>('cart');

const { pushCommerce, transformToCommerceItem } = useGTM();

const openCart = () => {
  open.value = true;
  pushCommerce('view_cart', {
    currency: 'DKK',
    value: cart.value.pricing.total,
    items: cart.value.items.map((item) => transformToCommerceItem(item.item.product, item.item.productVariant, 0, undefined, undefined, item.item.issueNumber)),
  });
};

const removeItem = async (itemId: number): Promise<void> => {
  if (!confirm('Er du sikker på du vil fjerne dette produkt?')) {
    return;
  }

  const result = await useCart().removeItem(cart.value.meta.cartId, itemId);
  if (!result) {
    toast.error('Beklager. Noget gik galt. Prøv igen.');
    return;
  }

  // Refresh cart.
  void useCart().refresh();

  // Show toast usccess.
  toast.success('Produktet er fjernet fra din kurv.');

  // Push tracking event.
  const selectedCartItem = cart.value.items.filter((cartItem) => cartItem.id === itemId);
  pushCommerce('remove_from_cart', {
    currency: 'DKK',
    value: selectedCartItem[0].item.placedPrice,
    items: [transformToCommerceItem(selectedCartItem[0].item.product, selectedCartItem[0].item.productVariant, 0, undefined, undefined, selectedCartItem[0].item.issueNumber)],
  });
};
</script>

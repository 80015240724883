<template>
  <header data-header class="sticky left-0 top-0 z-50 grid h-header w-full items-center bg-white" @click="resetHeader">
    <div style="grid-area: logo" class="ml-30">
      <NuxtLink
        to="/"
      >
        <SiteLogo />
      </NuxtLink>
    </div>
    <button type="button" aria-label="menu" data-aller-id="button-fly-in-menu" style="grid-area: menu-button" data-element-menu-button class="header-link mr-30 md:hidden" @click.stop="isMobileMenuOpen = !isMobileMenuOpen">
      <LazySharedIcon
        name="menu"
        class="w-24"
      />
    </button>
    <nav style="grid-area: menu" data-aller-id="fly-in-menu" data-primary-navigation class="duration-300 max-md:absolute max-md:left-0 max-md:top-0 max-md:h-[calc(100svh-var(--header-height))] max-md:w-full max-md:overflow-hidden max-md:bg-white md:flex md:justify-center" :class="[isMobileMenuOpen ? 'pointer-events-auto opacity-100' : 'max-md:pointer-events-none max-md:invisible max-md:opacity-0']">
      <ul class="text-14 font-semibold uppercase transition duration-300 max-md:space-y-20 max-md:p-30 md:flex md:items-center md:justify-center md:space-x-30" :class="{'max-md:-translate-x-full': isProductsOpen }" :data-products-open="isProductsOpen">
        <li v-for="item,index in menuItems" :key="index">
          <div v-if="item.subs?.length">
            <button type="button" class="header-link relative z-10 inline-flex items-center gap-x-3" :class="{ '!text-black': isProductsOpen }" @click.stop="isProductsOpen = !isProductsOpen">
              {{ item.label }}
              <LazySharedIcon
                name="chevron-right"
                class="w-16 origin-center"
                :class="[isProductsOpen ? 'rotate-270': 'rotate-90' ]"
              />
            </button>
            <div class="absolute left-0 top-0 z-0 w-full bg-white pb-30 pt-22 max-md:translate-x-full md:mt-[var(--header-height)] md:shadow-2xl" :class="[isProductsOpen ? 'md:opacity-100 md:duration-300' : 'md:pointer-events-none md:invisible md:opacity-0']">
              <button type="button" class="header-link flex items-center max-md:mb-10 max-md:ml-30 md:hidden" @click.stop="isProductsOpen = !isProductsOpen">
                <LazySharedIcon
                  name="chevron-right"
                  class="w-24 origin-center rotate-180"
                />
                <span>{{ productsText }}</span>
              </button>
              <div class="overflow-y-auto max-lg:h-[500px] max-lg:max-h-[calc(100svh-var(--header-height))]">
                <div class="relative px-30 max-md:pb-30">
                  <LazySiteProducts
                    v-if="isProductsOpen"
                    :filter="item.subs"
                    topmenu
                  />
                </div>
              </div>
            </div>
          </div>
          <NuxtLink
            v-else
            :to="item.route"
            class="header-link"
            @click="trackClick(item)"
          >
            {{ item.label }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
    <nav style="grid-area: misc">
      <ul class="mr-15 flex justify-end md:mr-30 md:space-x-30">
        <li>
          <Cart />
        </li>
        <li>
          <NuxtLink
            v-if="isUserLoggedIn"
            to="/mitaller/abonnementsoverblik"
            class="flex items-center gap-x-8 rounded-lg px-12 py-8 text-14 font-semibold uppercase leading-12 hover:bg-gray-100"
            :aria-label="loginText"
          >
            <LazySharedIcon
              name="profile"
              class="h-24 w-24"
            />
            <span class="hidden lg:block">{{ myPagesText }}</span>
          </NuxtLink>
          <NuxtLink
            v-else
            :to="{ name: 'logind' }"
            class="flex items-center gap-x-8 rounded-lg px-12 py-8 text-14 font-semibold uppercase leading-12 hover:bg-gray-100"
            :aria-label="loginText"
          >
            <LazySharedIcon
              name="profile"
              class="h-24 w-24"
            />
            <span class="hidden md:block">
              {{ loginText }}
            </span>
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { NavigationTransformer } from '@aller/nuxt-allerservice-sdk/transformers';
import type { OptimizelyContentResponse } from '@aller/nuxt-allerservice-sdk/types';

const { loggedIn: isUserLoggedIn } = useUserSession();

const isMobileMenuOpen = ref<boolean>(false);
const isProductsOpen = ref<boolean>(false);

const resetHeader = () => {
  isMobileMenuOpen.value = false;
  isProductsOpen.value = false;
};

// Get the base start data.
const start = useState<OptimizelyContentResponse>('start');
const menuItems = NavigationTransformer(start.value);

const trackClick = (item) => {
  useGTM().pushElement('click_top_menu_item', {
    section_id: 'siteHeader',
    section_text: undefined,
    element_id: item.route,
    element_text: item.label,
    element_link: item.route,
  });
};

const { products: productsText } = useString('menu');
const { login: loginText } = useString('login');
const { myPages: myPagesText } = useString('selfservice');
</script>

<style scoped>
/* Header */
[data-header] {
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'logo misc menu-button'
    'menu menu menu';
}

[data-header]:has(button[data-active='true']) {
  @apply bg-white;
}

@screen md {
  [data-header] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-areas: 'logo menu misc';
  }

  [data-primary-navigation] {
    [data-products-open="true"] {
      .header-link {
        @apply text-gray-200;

        &.inline-flex, &:hover {
          @apply text-black;
        }
      }
    }

    [data-products-open="false"] {
      &:hover {
        .header-link {
          &:not(:hover) {
            @apply text-gray-200;
          }

          &:hover {
            @apply text-black;
          }
        }
      }
    }
  }
}

.header-item {
  @apply relative z-50;
}

.header-link {
  @apply uppercase font-semibold text-14;
}

</style>
